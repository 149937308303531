import { DirectiveBinding } from "vue";

interface ClickOutsideElement extends HTMLElement {
    clickOutsideEvent?: (event: MouseEvent) => void;
}

export default {
    beforeMount(el: ClickOutsideElement, binding: DirectiveBinding) {
        el.clickOutsideEvent = (event: MouseEvent) => {
            // el(바인딩된 요소) 안을 클릭했다면 중단
            if (el.contains(event.target as Node)) {
                return;
            }
            // 그렇지 않은 경우 => 바깥을 클릭
            binding.value(event);
        };
        document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted(el: ClickOutsideElement) {
        if (el.clickOutsideEvent) {
            document.removeEventListener("click", el.clickOutsideEvent);
        }
    },
};
