import { defineStore } from "pinia";

import type { LoadingState, LoadingKey } from "./interface";

const useLoadingStore = defineStore<"loadingStore", LoadingState>({
    id: "loadingStore",
    state: () => ({
        isLoading: {
            postLogin: false,
            postLogout: false,
            postAcademyCandidates: false,
            getCurriculumMetadata: false,
            getAcademyStudents: false,
            getAcademyStudentBaseToken: false,
            getInitialAcademyUsers: false,
            getAcademyRegistrationRequestStudent: false,
            getPendingRegistrationList: false,
            getStudent: false,
            getSchools: false,
            getExpectedAttendees: false,
            getExpectedAttendeesBaseToken: false,
            getPreviewPlannedCurriculumMetadata: false,
            getPreviewPlannedCompletedCurriculumMetadata: false,
            getAllTypeAttendanceRecord: false,
            getAttendanceRecordBaseRange: false,
            getTotalCurriculumListData: false,
            getChallengeTemplates: false,
            getChallengeTemplatesNextPage: false,
            getChallenges: false,
            getChallengesNextPage: false,
            getChallengeListBaseTemplateId: false,
            getChallengeListBaseTemplateIdNextPage: false,
            getProblemsByCurriculumIds: false,
            getProblemsByCurriculumIdsNextPage: false,
            getProvidedChallengeRecords: false,
            getProvidedChallengeRecordsNextPage: false,
            getStudentChallenges: false,
            getStudentChallengesNextPage: false,
            getChallengeSearchStudents: false,
            getChallengeSearchStudentsTableRender: false,
            getAcademyClasses: false,
            getStudentSubmittedProblems: false,
            getStudentSubmittedProblemsNextPage: false,
            getAfterChallengeTemplateProblems: false,
            getAfterChallengeTemplateProblemsNextPage: false,
            getConceptEnhancementList: false,
            getNextPageConceptEnhancementList: false,
            getNextTokenBaseAttendanceRecord: false,
            postCandidateStudentInvitation: false,
            postCurriculum: false,
            postBeforeChallengeTemplate: false,
            postAfterChallengeTemplate: false,
            provideChallengeToStudents: false,
            patchStudentClass: false,
            patchAcademyCandidates: false,
            patchStudentCurriculumMetadata: false,
            patchResetCurriculumMetadata: false,
            deleteCurriculum: false,
            deleteCandidate: false,
            curriculumPagination: false,
            completedCurriculumPagination: false,
            global: false,
        },
    }),
    actions: {
        setLoading(key: LoadingKey, value: boolean) {
            this.isLoading[key] = value;
        },
    },
});

export default useLoadingStore;
