import { inject, singleton } from "tsyringe";

// adapter
import DateFns from "@/infrastructure/libs/date/DateFns";

import {
    IDateAdapter,
    ParseISOAdapterOptions,
    FormatISOAdapterOptions,
    FormatDateAdapterOptions,
} from "./interface";

@singleton()
export default class DateAdapter implements IDateAdapter {
    constructor(@inject(DateFns) private readonly dateFns: DateFns) {}

    public parseISO = (date: string, options?: ParseISOAdapterOptions): Date => {
        return this.dateFns.parseISO(date, options);
    };

    public formatISO = (date: Date, options?: FormatISOAdapterOptions): string => {
        return this.dateFns.formatISO(date, options);
    };

    public parseZoned = (date: Date, timeZone: string): Date => {
        return this.dateFns.parseZoned(date, timeZone);
    };

    public formatZoned = (
        date: Date,
        timeZone: string,
        formatStr?: string,
        options?: FormatDateAdapterOptions,
    ): string => {
        return this.dateFns.formatZoned(date, timeZone, formatStr, options);
    };

    public addWeeks = (date: Date, weeks: number): Date => {
        return this.dateFns.addWeeks(date, weeks);
    };

    public subWeeks = (date: Date, weeks: number): Date => {
        return this.dateFns.subWeeks(date, weeks);
    };

    public differenceInDays = (dateLeft: Date, dateRight: Date): number => {
        return this.dateFns.differenceInDays(dateLeft, dateRight);
    };

    public differenceInWeeks = (dateLeft: Date, dateRight: Date): number => {
        return this.dateFns.differenceInWeeks(dateLeft, dateRight);
    };

    public setHours = (
        date: Date,
        hours: number,
        minutes?: number,
        seconds?: number,
        milliseconds?: number,
    ): Date => {
        return this.dateFns.setHours(date, hours, minutes, seconds, milliseconds);
    };

    public formatQueryDate = (date: Date): string => {
        return this.dateFns.formatQueryDate(date);
    };
}
