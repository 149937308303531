import { container } from "tsyringe";

// behaviors

// submitted-action
import { SubmittedActionBehavior } from "@/domain/submitted-action/behaviors/SubmittedActionBehavior";

// concept-enhancement
import { ConceptEnhancementBehavior } from "@/domain/concept-enhancement/behaviors/ConceptEnhancementBehavior";

export const behaviorRegisterInit = () => {
    container.register<SubmittedActionBehavior>("SubmittedActionBehavior", {
        useClass: SubmittedActionBehavior,
    });

    container.register<ConceptEnhancementBehavior>("ConceptEnhancementBehavior", {
        useClass: ConceptEnhancementBehavior,
    });
};
