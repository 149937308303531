import { singleton, inject } from "tsyringe";

// domain
import {
    ProblemRepository,
    RequestGetProblemsByCurriculumIds,
    RequestGetProblemsByProblemIds,
} from "@/domain/problem/repositories/repositories";
import { GetProblemsResponseDto } from "@/domain/problem/dtos";

// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http";

@singleton()
export default class ProblemRepositoryImpl implements ProblemRepository {
    constructor(@inject(HttpAdaptor) private readonly server: HttpAdaptor) {}

    getProblemsByCurriculumIds = async (payload: RequestGetProblemsByCurriculumIds) => {
        let url = `/v1/problems?`;

        // curriculumId query
        payload.curriculumIds.forEach((curriculumId) => {
            url += `curriculumId=${curriculumId}&`;
        });

        // timeDifficulty query
        payload.timeDifficulties.forEach((timeDifficulty) => {
            url += `timeDifficulty=${timeDifficulty}&`;
        });

        url += `size=${payload.size}&`;

        if (payload.nextToken) {
            url += `nextToken=${payload.nextToken}`;
        }

        const response = await this.server.get<GetProblemsResponseDto>(url);

        return response;
    };

    getProblemsByProblemIds = async (payload: RequestGetProblemsByProblemIds) => {
        let url = `/v1/problems?`;

        payload.problemIds.forEach((id) => {
            url += `problemId=${id}&`;
        });

        url += `size=${payload.size}`;

        if (payload.nextToken) {
            url += `nextToken=${payload.nextToken}`;
        }

        const response = await this.server.get<GetProblemsResponseDto>(url);

        return response;
    };
}
