import { defineStore } from "pinia";

import type {
    IConceptEnhancementState,
    IConceptEnhancementActions,
    IGetConceptEnhancementsResponseDto,
} from "./interface";

const useConceptEnhancementStore = defineStore<
    "conceptEnhancementStore",
    IConceptEnhancementState,
    {},
    IConceptEnhancementActions
>("conceptEnhancementStore", {
    state: (): IConceptEnhancementState => ({
        conceptEnhancementsDto: null,
        nextToken: null,
    }),
    actions: {
        setConceptEnhancementsDto(dto: IGetConceptEnhancementsResponseDto) {
            this.conceptEnhancementsDto = dto;
        },
        setNextToken(nextToken: string) {
            this.nextToken = nextToken;
        },
        resetState() {
            this.conceptEnhancementsDto = null;
            this.nextToken = null;
        },
    },
});

export default useConceptEnhancementStore;
