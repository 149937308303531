<script setup lang="ts">
import NavigationBar from "@/ui/shared/components/navigation/navigationBar/NavigationBar.vue";
import type {
    NavigationListsProps,
    NavigationListsEmits,
} from "@/ui/shared/components/navigation/navigationLists/NavigationListsModel";

defineProps<NavigationListsProps>();
const emits = defineEmits<NavigationListsEmits>();

/** 상위 컴포넌트로 selected-index 를 전달합니다.
 * TODO: 추후에 inject, provide 로 변경할 예정입니다.
 */
const emitSelectedMenuIndex = (selectedIndex: number) => {
    emits("emit-selected-index", selectedIndex);
};
</script>

<template>
    <div class="flex flex-col justify-center items-start gap-[0.8rem] w-full">
        <div v-for="menu in lists" :key="menu.index" class="w-full">
            <NavigationBar
                :index="menu.index"
                :value="menu.value"
                :icon="menu.icon"
                :selected-index="selectedIndex"
                :path="menu.path"
                @emit-selected-index="emitSelectedMenuIndex"
            />
        </div>
    </div>
</template>
