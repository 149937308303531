import { singleton } from "tsyringe";

import type { AdapterErrorResponse } from "@/adapter/common/errors/interface";

@singleton()
export default class AccessTokenErrorChecker {
    public check = (error: AdapterErrorResponse) => {
        // TODO: 생각해봐야 할 것은 위 메시지가 변동이 있을 때의 상황이다..
        if (
            error.errorMessage &&
            ["jwt malformed", "Access Token Error"].includes(error.errorMessage)
        ) {
            return true;
        }

        if (
            ["COMMON-ACCOUNTS-004", "COMMON-ACCOUNTS-005", "COMMON-ACCOUNTS-999"].includes(
                error.errorCode,
            )
        ) {
            return true;
        }

        return false;
    };
}
