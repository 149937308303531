export enum SubmittedActionType {
    Answer = 0,
    Skip = 1,
    Postmortem = 2,
}

export enum SubmittedAnswerEvaluation {
    Better = 0,
    Normal = 1,
    Worse = 2,
}

export enum SkipType {
    /** 쉬워요 */
    Easy = 0,
    /** 어려워요 */
    Hard = 1,
    /** 시스템이 판단하기에 풀지 않아도 되는 문제로 판단한 경우 */
    SystemDefinedEasy = 2,
    /** 모르겠어요 */
    DoNotUnderstand = 3,
    /** 호출에 의한 스킵 */
    ByCall = 4,
    /** 질문에 의한 스킵 */
    ByAsk = 5,
}

export const enum SubmittedActionSourceType {
    Study = 0,
    StudyReview = 1,
    Retry = 2,
    Postmortem = 3,
    Challenge = 4,
    ConceptEnhancement = 5,
}
