import { defineStore } from "pinia";

import type { IProblemState, IProblemActions, IGetProblemsResponseDto } from "./interface";

const useProblemsStore = defineStore<"problemsStore", IProblemState, {}, IProblemActions>(
    "problemsStore",
    {
        state: (): IProblemState => ({
            problemsResponseDto: null,
            nextToken: null,
        }),
        actions: {
            setProblems(dto: IGetProblemsResponseDto) {
                this.problemsResponseDto = dto;
            },
            setNextToken(nextToken: string | null) {
                this.nextToken = nextToken;
            },
            resetState() {
                this.problemsResponseDto = null;
                this.nextToken = null;
            },
        },
    },
);

export default useProblemsStore;
