import { inject, singleton } from "tsyringe";
import axios from "axios";

// infrastructure
import { REFRESH_URL } from "@/infrastructure/libs/http/axiosFetcher";
import { AccessStorage } from "@/infrastructure/repositoriesImpl/common/storage/AccessStorageImpl";

export interface RefreshTokenResponse {
    accessToken: string;
    refreshToken: string;
}

@singleton()
export class AccessTokenRefresher {
    promise: Promise<string> | undefined;
    baseURL: string;

    constructor(@inject("AccessStorage") private accessStorage: AccessStorage) {
        this.promise = undefined;
        this.baseURL = import.meta.env.VITE_API_URL;
    }

    public refresh = async (userRefreshToken: string): Promise<string> => {
        /** refresh 요청 중이라면 */
        if (this.promise) return this.promise;

        /** accessToken 을 다시 반환
         * 테스트 : import.meta.env.VITE_DOCKER_API_URL
         */
        this.promise = (async () => {
            try {
                const responseToken = await axios.post<RefreshTokenResponse>(
                    `${this.baseURL}${REFRESH_URL}`,
                    {
                        refreshToken: userRefreshToken,
                    },
                );
                /** 생성된지 7일이 지났다면 */
                if (responseToken.data.refreshToken !== undefined) {
                    this.accessStorage.setItem("userRefreshToken", responseToken.data.refreshToken);
                }
                this.accessStorage.setItem("userAccessToken", responseToken.data.accessToken);

                this.promise = undefined;

                return responseToken.data.accessToken;
            } catch (error) {
                this.promise = undefined;
                // refreshToken 을 삭제해야 재 로그인 모달창 open
                this.accessStorage.removeItem("userRefreshToken");
                return "";
            }
        })();

        return this.promise;
    };
}
