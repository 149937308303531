import { singleton } from "tsyringe";
import {
    formatISO,
    parseISO,
    format,
    addWeeks,
    subWeeks,
    differenceInDays,
    differenceInWeeks,
    set,
} from "date-fns";
import { TZDate } from "@date-fns/tz";

import {
    IDateAdapter,
    ParseISOAdapterOptions,
    FormatISOAdapterOptions,
    FormatDateAdapterOptions,
} from "./interface";

@singleton()
export default class DateFns implements IDateAdapter {
    constructor() {}

    public parseISO = (date: string, options?: ParseISOAdapterOptions): Date => {
        return parseISO(date, options?.fn);
    };

    public formatISO = (date: Date, options?: FormatISOAdapterOptions): string => {
        return formatISO(date, options?.fn);
    };

    public parseZoned = (date: Date, timeZone: string): Date => {
        return new TZDate(date, timeZone);
    };

    public formatZoned = (
        date: Date,
        timeZone: string,
        formatStr?: string,
        options?: FormatDateAdapterOptions,
    ): string => {
        const dateWithTimeZone = new TZDate(date, timeZone);

        return format(dateWithTimeZone, formatStr ?? "yyyy-MM-dd HH:mm:ss", options?.fn);
    };

    public addWeeks = (date: Date, weeks: number): Date => {
        return addWeeks(date, weeks);
    };

    public subWeeks = (date: Date, weeks: number): Date => {
        return subWeeks(date, weeks);
    };

    public differenceInDays = (dateLeft: Date, dateRight: Date): number => {
        return differenceInDays(dateLeft, dateRight);
    };

    public differenceInWeeks = (dateLeft: Date, dateRight: Date): number => {
        return differenceInWeeks(dateLeft, dateRight);
    };

    public setHours = (
        date: Date,
        hours: number,
        minutes?: number,
        seconds?: number,
        milliseconds?: number,
    ): Date => {
        return set(date, { hours, minutes, seconds, milliseconds });
    };

    public formatQueryDate = (date: Date): string => {
        const setTimezone = new TZDate(date, "Asia/Seoul");
        const formattedISODate = formatISO(setTimezone);

        return encodeURIComponent(formattedISODate);
    };
}
