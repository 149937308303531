import { singleton } from "tsyringe";

// 구조체
export interface AccessStorage {
    getItem: (key: string) => string | null;
    setItem: <T extends string>(key: string, value: T) => void;
    removeItem: (key: string) => void;
}

@singleton()
export class AccessStorageImpl implements AccessStorage {
    public getItem = (key: string) => {
        return localStorage.getItem(key);
    };

    public setItem = <T extends string>(key: string, value: T) => {
        localStorage.setItem(key, value);
    };

    public removeItem = (key: string) => {
        localStorage.removeItem(key);
    };
}
