import { inject, singleton } from "tsyringe";
import axios from "axios";
import { AccessTokenRefresher } from "@/infrastructure/libs/http/axiosTokenRefresher";
import { AccessStorage } from "@/infrastructure/repositoriesImpl/common/storage/AccessStorageImpl";
import type { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig, AxiosError } from "axios";

export interface ErrorResponse {
    errorCode: string;
    errorMessage?: string;
    errorData?: any;
}

export const REFRESH_URL = "/v2/refresh-token";

@singleton()
export class AxiosInterceptors {
    instance: AxiosInstance | null;

    constructor(
        @inject("AccessStorage") private accessStorage: AccessStorage,
        @inject(AccessTokenRefresher) private accessTokenRefresher: AccessTokenRefresher,
    ) {
        this.instance = null;
    }

    private logOnDev = (message: string) => {
        if (import.meta.env.MODE === "development") {
            console.log(message);
        }
    };

    private onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
        const { method } = config;

        if (method === "GET") {
            config.params = {
                ...config.params,
                _t: Date.now(),
            };
            config.timeout = 15000;
        }

        return config;
    };

    private onResponse = (response: AxiosResponse): AxiosResponse => {
        // store 내 저장될 요소들이 있습니다.
        return response;
    };

    private onError = async (error: ErrorResponse | undefined) => {
        return Promise.resolve();
    };

    private onErrorResponse = async (error: AxiosError<ErrorResponse>) => {
        const userRefreshToken = this.accessStorage.getItem("userRefreshToken");

        /** error */
        const { config, response } = error;
        const errorCode = response?.data.errorCode;
        const axiosErrorResponse = response?.data;

        this.logOnDev(`[API]| Error ${error.message}`);
        this.onError(axiosErrorResponse);

        /** 우선 Request RefreshToken 으로 요청된 URL 을 파악하고 이미 재갱신 되어있는지 확인 */
        if (
            config?.url === REFRESH_URL ||
            (response?.status !== 401 && errorCode !== "SLC-APPLICATION-901") ||
            userRefreshToken === null
        ) {
            return Promise.reject(error);
        }

        /** 재갱신이 필요하다면 재갱신 시도 */
        const accessToken = await this.accessTokenRefresher.refresh(userRefreshToken);

        /** accessToken 이 존재하지 않는다면 재 시도를 하지않고 return 합니다. */
        if (!accessToken) {
            return Promise.reject(error);
        }

        /** accessToken 이 존재한다면 */
        if (accessToken && error.config) {
            error.config.headers.Authorization = `bearer ${accessToken}`;
        }

        return axios(config as InternalAxiosRequestConfig);
    };

    private setUserTokenOnHeader = (
        config: InternalAxiosRequestConfig,
    ): InternalAxiosRequestConfig => {
        const userAccessToken = this.accessStorage.getItem("userAccessToken");

        /** refreshToken 을 통해 AccessToken 을 재 발급받는 경우에는 기존 AccessToken 을 Header 에 담지 않습니다 */
        if (config.url !== REFRESH_URL && userAccessToken !== "") {
            config.headers.Authorization = `bearer ${userAccessToken}`;
        }
        return this.onRequest(config);
    };

    private setupInterceptors = () => {
        this.instance?.interceptors.request.use(this.setUserTokenOnHeader);
        this.instance?.interceptors.response.use(this.onResponse, this.onErrorResponse);
    };

    public createInstance = () => {
        this.instance = axios.create({
            baseURL: import.meta.env.VITE_API_URL,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Cache-Control": "no-cache, no-store, must-revalidate",
            },
        });

        this.setupInterceptors();
        return this.instance;
    };
}

@singleton()
export default class AxiosAdaptor {
    instance: AxiosInstance;

    constructor(@inject(AxiosInterceptors) private axiosInterceptors: AxiosInterceptors) {
        this.instance = this.axiosInterceptors.createInstance();
    }

    public get = async <T>(url: string, signal?: AbortSignal): Promise<T> => {
        const response = await this.instance.get(url, { signal });

        return response.data;
    };

    public post = async <T>(url: string, data: any): Promise<T> => {
        const response = await this.instance.post(url, data);

        return response.data;
    };

    public patch = async <T>(url: string, data: any): Promise<T> => {
        const response = await this.instance.patch(url, data);

        return response.data;
    };

    public put = async <T>(url: string, data: any): Promise<T> => {
        const response = await this.instance.put(url, data);

        return response.data;
    };

    public delete = async <T>(url: string): Promise<T> => {
        const response = await this.instance.delete(url);

        return response.data;
    };
}
