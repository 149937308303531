import { singleton, inject } from "tsyringe";

import type {
    UserRepository,
    RequestPatchUserEnhancementSetting,
} from "@/domain/user/repositories/repositories";

import HttpClient from "@/infrastructure/libs/http";

@singleton()
export default class UserRepositoryImpl implements UserRepository {
    constructor(
        @inject(HttpClient)
        private readonly httpClient: HttpClient,
    ) {}

    public patchUserConceptEnhancementSetting = async (
        userId: string,
        payload: RequestPatchUserEnhancementSetting,
    ) => {
        const url = `/v1/users/${userId}`;

        await this.httpClient.patch(url, payload);
    };
}
