<script setup lang="ts">
import { onErrorCaptured } from "vue";
import { container } from "tsyringe";

// infra
import { ErrorResponse } from "@/infrastructure/libs/http/axiosFetcher";
import ConfirmLibsError from "@/infrastructure/libs/http/ConfirmAxiosError";
import { AccessStorage } from "@/infrastructure/repositoriesImpl/common/storage/AccessStorageImpl";

// adapter
import AccessTokenErrorChecker from "@/adapter/common/errors/AccessTokenErrorChecker";
import { ClientStateManager } from "@/adapter/common/stores/client/interface";

// instance
const alertManager = container.resolve<ClientStateManager.AlertManager>("AlertManager");
const accessTokenErrorChecker = container.resolve(AccessTokenErrorChecker);
const confirmLibsError = container.resolve(ConfirmLibsError);
const accessStorage = container.resolve<AccessStorage>("AccessStorage");

/** root error boundary */
onErrorCaptured((error) => {
    if (confirmLibsError.isAxiosError<ErrorResponse>(error)) {
        if (error.name === "CanceledError") return false;

        const errorResponse = error.response?.data;

        /** code 가 지정되어있지 않는 에러에 한하여 throw */
        if (!errorResponse?.errorCode) {
            alertManager.dispatch("onmountAlertModal", [
                "AlertUnExpectedError",
                {
                    title: `관리자에게 문의해주세요.`,
                    message: error.message,
                },
            ]);
            return true;
        }

        /** accessToken 이상 시  */
        if (accessTokenErrorChecker.check(errorResponse)) {
            if (accessStorage.getItem("userRefreshToken")) {
                return true;
            }
            alertManager.dispatch("onmountAlertModal", [
                "AlertInvalidTokenError",
                {
                    title: "로그인 정보를 갱신해야합니다. 로그인 페이지로 이동합니다.",
                    isModalClose: true,
                    isStrong: true,
                },
            ]);
            return false;
        }

        alertManager.dispatch("onmountAlertModal", [
            "AlertUnExpectedError",
            {
                title: `관리자에게 문의해주세요.`,
                message: `${errorResponse.errorCode}\n${errorResponse.errorMessage}`,
            },
        ]);
        return false;
    } else {
        if (alertManager.getState().alertOpen) return true;
        alertManager.dispatch("onmountAlertModal", [
            "AlertUnExpectedError",
            {
                title: `관리자에게 문의해주세요.`,
                message: error.message,
            },
        ]);
        return true;
    }
});
</script>

<template>
    <div class="w-full h-full">
        <slot></slot>
    </div>
</template>

<style scoped></style>
