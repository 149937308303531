import { singleton, inject } from "tsyringe";

// domain
import {
    StudentRepository,
    RequestAcademyStudentsPayload,
    RequestPatchUserEnhancementSetting,
} from "@/domain/user/student/repositories/repositories";
import { GetAcademyStudentsResponseDto, StudentDto } from "@/domain/user/student/dtos";

// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http";

@singleton()
export default class StudentRepositoryImpl implements StudentRepository {
    constructor(@inject(HttpAdaptor) private readonly server: HttpAdaptor) {}

    /** @description 챌린지 템플릿 목록 조회 */
    public getAcademyStudents = async (payload: RequestAcademyStudentsPayload) => {
        let url = `/v2/academies/${payload.academyId}/students?keyword=${payload.keyword}&size=${payload.size}&include=class&`;

        if (payload.nextToken) {
            url += `nextToken=${payload.nextToken}`;
        }

        const response = await this.server.get<GetAcademyStudentsResponseDto>(url);

        return response;
    };

    /**
     * @description 학생의 모든 정보를 가져옵니다.
     * @param studentId 학생 아이디
     * @returns
     */
    public getAcademyStudentAllInformation = async (studentId: string) => {
        const url = `/v2/students/${studentId}?include=timetable&include=curriculumMetadata&include=options`;

        const response = await this.server.get<StudentDto>(url);

        return response;
    };

    /**
     * @description 학생의 정보 중 튼튼 개념 쌓기에 대한 정보를 추가해서 가져옵니다.
     * @param studentId 학생아이디
     * @returns
     */
    public getStudentIncludeConceptEnhancementSetting = async (studentId: string) => {
        const url = `/v2/students/${studentId}?include=options`;

        const response = await this.server.get<StudentDto>(url);

        return response;
    };

    /**
     * @description 학생의 튼튼 개념 쌓기 설정을 수정합니다.
     * @param studentId 학생 아이디
     * @param payload 수정할 설정 정보
     */
    public patchStudentConceptEnhancementSetting = async (
        studentId: string,
        payload: RequestPatchUserEnhancementSetting,
    ) => {
        const url = `/v3/students/${studentId}?include=options`;

        await this.server.patch(url, payload);
    };
}
