import { singleton, inject } from "tsyringe";

// root behavior
import { SubmittedActionBehavior } from "@/domain/submitted-action/behaviors/SubmittedActionBehavior";

import type {
    ConceptEnhancementDto,
    ConceptEnhancementTestDto,
    ConceptEnhancementPretestDto,
    CommonConceptEnhancementChapterDto,
    ConceptEnhancementContentDto,
} from "@/domain/concept-enhancement/dtos";
import { ConceptEnhancementChapterType } from "@/domain/concept-enhancement/enums";
import { Evaluation } from "@/domain/study/enums";

// structure
export interface ConceptEnhancementBehaviorStructure {
    caculateLatestChapterAnswerRatio: (conceptEnhancement: ConceptEnhancementDto) => {
        correctProblemCount: number;
        submittedProblemCount: number;
    };
    caculateChaptersAnswerRatio: (conceptEnhancement: ConceptEnhancementDto) => {
        round: number;
        correctProblemCount: number;
        submittedProblemCount: number;
    }[];
    checkChapterSucceed: (chapter: CommonConceptEnhancementChapterDto) => boolean | null;
    isConceptEnhancementTestChapter: (
        chapter: CommonConceptEnhancementChapterDto,
    ) => chapter is ConceptEnhancementTestDto;
    isConceptEnhancementPreTestChapter: (
        chapter: CommonConceptEnhancementChapterDto,
    ) => chapter is ConceptEnhancementPretestDto;
    isConceptEnhancementContentChapter: (
        chapter: CommonConceptEnhancementChapterDto,
    ) => chapter is ConceptEnhancementContentDto;
    filterOnlyTestChapter: (
        conceptEnhancement: ConceptEnhancementDto,
    ) => Array<CommonConceptEnhancementChapterDto>;
}

@singleton()
export class ConceptEnhancementBehavior implements ConceptEnhancementBehaviorStructure {
    constructor(
        @inject(SubmittedActionBehavior)
        private readonly submittedActionBehavior: SubmittedActionBehavior,
    ) {}

    public caculateLatestChapterAnswerRatio = (conceptEnhancement: ConceptEnhancementDto) => {
        // 정답률 계산하기

        const conceptEnhancementChapters = conceptEnhancement.chapters;

        if (!conceptEnhancementChapters) {
            return {
                correctProblemCount: 0,
                submittedProblemCount: 0,
            };
        }

        // completedAt 이 없는 chapter 의 경우 삭제하기
        // 또한 컨텐츠 챕터인 경우도 삭제하기
        const completedConceptEnhancementChapters = conceptEnhancementChapters.filter(
            (chapter): chapter is ConceptEnhancementTestDto | ConceptEnhancementPretestDto => {
                return (
                    chapter.completedAt !== undefined &&
                    !this.isConceptEnhancementContentChapter(chapter)
                );
            },
        );

        if (completedConceptEnhancementChapters.length === 0) {
            return {
                correctProblemCount: 0,
                submittedProblemCount: 0,
            };
        }

        const conceptEnhancementLatestChapter =
            completedConceptEnhancementChapters[completedConceptEnhancementChapters.length - 1];

        const submittedProblemCount =
            conceptEnhancementLatestChapter.conceptEnhancementProblems.filter(
                (conceptEnhancementProblem) => conceptEnhancementProblem.submittedAction,
            ).length;
        const correctProblemCount =
            conceptEnhancementLatestChapter.conceptEnhancementProblems.filter(
                (conceptEnhancementProblem) => {
                    const simplifiedSubmittedAction = conceptEnhancementProblem.submittedAction;
                    if (!simplifiedSubmittedAction) return false;

                    if (
                        !this.submittedActionBehavior.isSimplifiedSubmittedAnswer(
                            simplifiedSubmittedAction,
                        )
                    )
                        return false;

                    return simplifiedSubmittedAction.evaluation === Evaluation.Correct;
                },
            ).length;

        return {
            correctProblemCount,
            submittedProblemCount,
        };
    };

    public caculateChaptersAnswerRatio = (conceptEnhancement: ConceptEnhancementDto) => {
        // 완료된 챕터이면서, test 타입이나 Pretest 타입인 챕터만 조회합니다.
        // 완료되지 않은 챕터이면서 content 라면 애초에 submitAction 을 기반으로 정답률을 구할 수 없습니다.
        const chapters = conceptEnhancement.chapters
            ?.filter((chapter) => chapter.completedAt)
            .filter(
                (chapter): chapter is ConceptEnhancementTestDto | ConceptEnhancementPretestDto =>
                    this.isConceptEnhancementTestChapter(chapter) ||
                    this.isConceptEnhancementPreTestChapter(chapter),
            );

        if (!chapters || chapters.length === 0) return [];

        const chaptersAnswerRatioSet = chapters.map((chapter) => {
            const submittedProblemCount = chapter.conceptEnhancementProblems.filter(
                (conceptEnhancementProblem) => conceptEnhancementProblem.submittedAction,
            ).length;
            const correctProblemCount = chapter.conceptEnhancementProblems.filter(
                (conceptEnhancementProblem) => {
                    const simplifiedSubmittedAction = conceptEnhancementProblem.submittedAction;
                    if (!simplifiedSubmittedAction) return false;

                    if (
                        !this.submittedActionBehavior.isSimplifiedSubmittedAnswer(
                            simplifiedSubmittedAction,
                        )
                    )
                        return false;

                    return simplifiedSubmittedAction.evaluation === Evaluation.Correct;
                },
            ).length;

            return {
                round: chapter.round,
                correctProblemCount: correctProblemCount,
                submittedProblemCount: submittedProblemCount,
            };
        });

        return chaptersAnswerRatioSet;
    };

    public checkChapterSucceed = (chapter: CommonConceptEnhancementChapterDto) => {
        if (this.isConceptEnhancementContentChapter(chapter)) return null;

        return chapter.succeedAt ? true : false;
    };

    public isConceptEnhancementTestChapter = (
        chapter: CommonConceptEnhancementChapterDto,
    ): chapter is ConceptEnhancementTestDto => {
        return chapter.type === ConceptEnhancementChapterType.Test;
    };

    public isConceptEnhancementPreTestChapter = (
        chapter: CommonConceptEnhancementChapterDto,
    ): chapter is ConceptEnhancementPretestDto => {
        return chapter.type === ConceptEnhancementChapterType.Pretest;
    };

    public isConceptEnhancementContentChapter = (
        chapter: CommonConceptEnhancementChapterDto,
    ): chapter is ConceptEnhancementContentDto => {
        return chapter.type === ConceptEnhancementChapterType.Content;
    };

    public filterOnlyTestChapter = (conceptEnhancement: ConceptEnhancementDto) => {
        const chapters = conceptEnhancement.chapters;

        if (!chapters) return [];

        return chapters
            .filter((chapter) => !this.isConceptEnhancementContentChapter(chapter))
            .filter((chapter) => chapter.completedAt);
    };
}
