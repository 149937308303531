import { defineStore } from "pinia";

import type {
    IAssignedCurriculumProblemState,
    IAssignedCurriculumProblemActions,
    IGetAssignedCurriculumProblemResponseDto,
} from "./interface";

const useAssignedCurriculumProblemStore = defineStore<
    "assignedCurriculumProblemStore",
    IAssignedCurriculumProblemState,
    {},
    IAssignedCurriculumProblemActions
>("assignedCurriculumProblemStore", {
    state: (): IAssignedCurriculumProblemState => ({
        assignedCurriculumProblems: null,
        nextToken: null,
    }),
    actions: {
        setAssignedCurriculumProblems(dto: IGetAssignedCurriculumProblemResponseDto) {
            this.assignedCurriculumProblems = dto;
        },
        setNextToken(nextToken: string | null) {
            this.nextToken = nextToken;
        },
        resetState() {
            this.assignedCurriculumProblems = null;
            this.nextToken = null;
        },
    },
});

export default useAssignedCurriculumProblemStore;
