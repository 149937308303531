import { singleton } from "tsyringe";

import type {
    SimplifiedSubmittedActionDto,
    SimplifiedSubmittedAnswerDto,
} from "@/domain/submitted-action/dtos";
import { SubmittedActionType } from "@/domain/submitted-action/enums";

@singleton()
export class SubmittedActionBehavior {
    public isSimplifiedSubmittedAnswer = (
        simplifiedSubmittedActionDto: SimplifiedSubmittedActionDto,
    ): simplifiedSubmittedActionDto is SimplifiedSubmittedAnswerDto => {
        return simplifiedSubmittedActionDto.type === SubmittedActionType.Answer;
    };
}
