export enum ConceptEnhancementSucceedStatus {
    Succeed = 0,
    Failed = 1,
}

export enum ConceptEnhancementChapterType {
    Content = 0,
    Test = 1,
    Pretest = 2,
}
