import { inject, singleton } from "tsyringe";
import {
    ConceptEnhancementRepository,
    RequestGetConceptEnhancementsPayload,
} from "@/domain/concept-enhancement/repositories/repository";
import { GetConceptEnhancementsResponseDto } from "@/domain/concept-enhancement/dtos";
// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http";

@singleton()
export default class ConceptEnhancementRepositoryImpl implements ConceptEnhancementRepository {
    constructor(@inject(HttpAdaptor) private readonly server: HttpAdaptor) {}

    async getConceptEnhancements(
        payload: RequestGetConceptEnhancementsPayload,
    ): Promise<GetConceptEnhancementsResponseDto> {
        let url = `/v1/concept-enhancements?include=class&include=chapters&academyId=${payload.academyId}&`;

        if (payload.from) {
            url += `from=${payload.from}&`;
        }

        if (payload.to) {
            url += `to=${payload.to}&`;
        }

        if (payload.orders) {
            url += `orders=${payload.orders}&`;
        }

        if (payload.classId) {
            url += `classId=${payload.classId}&`;
        }

        if (payload.succeedStatus !== undefined) {
            url += `succeedStatus=${payload.succeedStatus}&`;
        }

        if (payload.keyword) {
            url += `keyword=${payload.keyword}&`;
        }

        if (payload.semesterId) {
            url += `semesterId=${payload.semesterId}&`;
        }

        if (payload.middleUnitId && payload.middleUnitId.length > 0) {
            for (const middleUnitId of payload.middleUnitId) {
                url += `middleUnitId=${middleUnitId}&`;
            }
        }

        if (payload.size) {
            url += `size=${payload.size}&`;
        }

        if (payload.nextToken) {
            url += `nextToken=${payload.nextToken}&`;
        }

        const response = await this.server.get<GetConceptEnhancementsResponseDto>(url);

        return response;
    }
}
