import { singleton, inject } from "tsyringe";

import HttpAdaptor from "@/infrastructure/libs/http";
import type { ClassRepository } from "@/domain/class/repositories/repository";
import type { GetAcademyClassesResponseDto } from "@/domain/class/dtos";

@singleton()
export default class ClassRepositoryImpl implements ClassRepository {
    constructor(
        @inject(HttpAdaptor)
        private readonly httpAdaptor: HttpAdaptor,
    ) {}

    public getAcademyClasses = async (academyId: string): Promise<GetAcademyClassesResponseDto> => {
        const response = await this.httpAdaptor.get<GetAcademyClassesResponseDto>(
            `/v1/academies/${academyId}/classes?include=studentCount`,
        );

        return response;
    };
}
