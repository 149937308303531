<script setup lang="ts">
import { ref, watch } from "vue";
import { CommonAlert, CommonModal, CommonModalSecondLayer } from "@/ui/shared/components";
import { useRoute } from "vue-router";

const route = useRoute();
const transitionEffectName = ref("fade");

// route 이동이 base route 일 때만 transition 효과가 적용되도록 설정
watch(
    () => route.path,
    (from, to) => {
        const beforeRouteBase = from.split("/")[1];
        const afterRouteBase = to.split("/")[1];

        beforeRouteBase === afterRouteBase
            ? (transitionEffectName.value = "none")
            : (transitionEffectName.value = "fade");
    },
);
</script>

<template>
    <section class="flex items-center justify-center w-[138rem] xl:w-full">
        <section class="xl:w-full w-[160rem] h-dvh">
            <component :is="$route.meta.layout">
                <router-view v-slot="{ Component, route }">
                    <transition :name="transitionEffectName" mode="out-in">
                        <component :is="Component" :key="route.path"></component>
                    </transition>
                </router-view>
            </component>
            <CommonAlert />
            <CommonModal />
            <CommonModalSecondLayer />
        </section>
    </section>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.15s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.none-enter-active,
.none-leave-active {
    transition: none;
}

.none-enter-from,
.none-leave-to {
    opacity: 100;
}
</style>
