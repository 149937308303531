import { container } from "tsyringe";

// interface
import { IDateAdapter } from "@/infrastructure/libs/date/interface";

// impl
import { CreateOrderKeyImpl } from "@/infrastructure/libs/interface";
import DateAdapter from "@/infrastructure/libs/date";
import DateFns from "@/infrastructure/libs/date/DateFns";

// create-order-key
import CreateOrderKeyAdapter from "@/infrastructure/libs/create-order-key/index";
import FractionalIndexing from "@/infrastructure/libs/create-order-key/FractionalIndexing";

const libsRegisterInit = () => {
    container.register<CreateOrderKeyImpl>("CreateOrderKey", {
        useValue: new CreateOrderKeyAdapter(new FractionalIndexing()),
    });

    container.register<IDateAdapter>("DateAdapter", {
        useValue: new DateAdapter(new DateFns()),
    });
};

export default libsRegisterInit;
