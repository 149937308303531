import { singleton, inject } from "tsyringe";

// domain
import {
    AssignedCurriculumProblemRepository,
    RequestGetProblemsByCurriculumIds,
    RequestGetProblemsByProblemIds,
} from "@/domain/assigned-curriculum-problem/repositories/repository";
import { GetAssignedCurriculumProblemsResponseDto } from "@/domain/assigned-curriculum-problem/dtos";

// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http";

@singleton()
export default class AssignedCurriculumProblemRepositoryImpl
    implements AssignedCurriculumProblemRepository
{
    constructor(@inject(HttpAdaptor) private readonly server: HttpAdaptor) {}

    getAssignedCurriculumProblemsByCurriculumId = async (
        payload: RequestGetProblemsByCurriculumIds,
    ) => {
        let url = `/v1/assigned-curriculum-problems?`;

        // curriculumId query
        payload.curriculumIds.forEach((curriculumId) => {
            url += `curriculumId=${curriculumId}&`;
        });

        // timeDifficulty query
        payload.timeDifficulties.forEach((timeDifficulty) => {
            url += `timeDifficulty=${timeDifficulty}&`;
        });

        url += `size=${payload.size}&`;

        if (payload.nextToken) {
            url += `nextToken=${payload.nextToken}`;
        }

        const response = await this.server.get<GetAssignedCurriculumProblemsResponseDto>(url);

        return response;
    };

    getAssignedCurriculumProblemsByProblemId = async (payload: RequestGetProblemsByProblemIds) => {
        let url = `/v1/assigned-curriculum-problems?`;

        payload.problemIds.forEach((id) => {
            url += `problemId=${id}&`;
        });

        url += `size=${payload.size}`;

        if (payload.nextToken) {
            url += `nextToken=${payload.nextToken}`;
        }

        const response = await this.server.get<GetAssignedCurriculumProblemsResponseDto>(url);

        return response;
    };
}
