import { container } from "tsyringe";
import { defineStore } from "pinia";

import type { IAuthState, IPrincipalDto } from "./interface";
import { IUserType } from "./interface";

// infrastructure
import { AccessStorage } from "@/infrastructure/repositoriesImpl/common/storage/AccessStorageImpl";

const useAuthStore = defineStore<"authStore", IAuthState>({
    id: "authStore",
    state: () => ({
        userDto: null,
        userEmail: container.resolve<AccessStorage>("AccessStorage").getItem("userEmail") || "",
        academyId: container.resolve<AccessStorage>("AccessStorage").getItem("academyId") || "",
        teacherId: container.resolve<AccessStorage>("AccessStorage").getItem("teacherId") || "",
        userType: 0,
        userAccessToken:
            container.resolve<AccessStorage>("AccessStorage").getItem("userAccessToken") || null,
        userRefreshToken:
            container.resolve<AccessStorage>("AccessStorage").getItem("userRefreshToken") || null,
    }),
    getters: {
        getAcademyName() {
            const isPrincipal = this.userDto && this.userDto.type === IUserType.PrincipalTeacher;

            return isPrincipal
                ? (this.userDto as IPrincipalDto).academy?.name
                : "수학에 심장을 달다";
        },
    },
    actions: {
        resetState() {
            this.userDto = null;
            this.userEmail = "";
            this.academyId = "";
            this.teacherId = "";
            this.userType = 0;
            this.userAccessToken = null;
            this.userRefreshToken = null;
        },
        setUserEmail(userEmail: string) {
            this.userEmail = userEmail;
            container.resolve<AccessStorage>("AccessStorage").setItem("userEmail", userEmail);
        },
    },
    persist: true,
});

export default useAuthStore;
