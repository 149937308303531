import type { NavigationBarProps } from "@/ui/shared/components/navigation/navigationBar/NavigationbarModel";

export const NavigationMainMenuLists: NavigationBarProps[] = [
    // {
    //     index: 0,
    //     icon: "HomeIcon",
    //     value: "Home",
    //     selectedIndex: 0,
    //     path: "/home",
    // },
    {
        index: 1,
        icon: "PersonIcon",
        value: "학생 설정",
        selectedIndex: 0,
        path: "/student-setting",
    },
    // {
    //     index: 2,
    //     icon: "TwoPeopleIcon",
    //     value: "학생 관리",
    //     selectedIndex: 0,
    //     path: "/student-management",
    // },
    {
        index: 3,
        icon: "MultiPeopleIcon",
        value: "반 관리",
        selectedIndex: 0,
        path: "/class-management",
    },
    {
        index: 4,
        icon: "TrophyIcon",
        value: "챌린지업",
        selectedIndex: 0,
        path: "/challenge-up",
    },
    {
        index: 5,
        icon: "ThreeBoxIcon",
        value: "튼튼 개념 쌓기",
        selectedIndex: 0,
        path: "/concept-enhancement",
    },
    // {
    //     index: 4,
    //     icon: "CirclePersonIcon",
    //     value: "강사 관리",
    //     selectedIndex: 0,
    //     path: "/teacher-management",
    // },
    // {
    //     index: 5,
    //     icon: "MessageIcon",
    //     value: "메시지 관리",
    //     selectedIndex: 0,
    //     path: "/message-management",
    // },
    // {
    //     index: 6,
    //     icon: "GraphIcon",
    //     value: "통계 관리",
    //     selectedIndex: 0,
    //     path: "/statistical-management",
    // },
    // {
    //     index: 7,
    //     icon: "DatabaseIcon",
    //     value: "경영 관리",
    //     selectedIndex: 0,
    //     path: "/business-management",
    // },
    // {
    //     index: 8,
    //     icon: "SeatIcon",
    //     value: "좌석 설정",
    //     selectedIndex: 0,
    //     path: "",
    // },
    // {
    //     index: 9,
    //     icon: "SettingIcon",
    //     value: "설정",
    //     selectedIndex: 0,
    //     path: "/setting",
    // },
];

export const NavigationSubMenuLists: NavigationBarProps[] = [
    // {
    //     index: 10,
    //     icon: "QuestionIcon",
    //     value: "고객센터",
    //     selectedIndex: 0,
    //     path: "/customer-center",
    // },
    {
        index: 11,
        icon: "ExitIcon",
        value: "로그아웃",
        selectedIndex: 0,
        path: "",
    },
];
