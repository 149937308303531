import { container } from "tsyringe";

// domain
import { UserRepository } from "@/domain/user/repositories/repositories";
import { ChallengeTemplateRepository } from "@/domain/challenge-template/repositories/repositories";
import { ChallengeRepository } from "@/domain/challenge/repositories/repositories";
import { StudentChallengeRepository } from "@/domain/academy/student-challenge/repositories/repositories";
import { SubmittedProblemRepository } from "@/domain/user/student/submitted-problems/repositories/repositories";
import { StudentRepository } from "@/domain/user/student/repositories/repositories";
import { ProblemRepository } from "@/domain/problem/repositories/repositories";
import { AssignedCurriculumProblemRepository } from "@/domain/assigned-curriculum-problem/repositories/repository";
import { ConceptEnhancementRepository } from "@/domain/concept-enhancement/repositories/repository";
import { ClassRepository } from "@/domain/class/repositories/repository";

// infrastructure
import UserRepositoryImpl from "@/infrastructure/repositoriesImpl/user/UserRepositoryImpl";
import ChallengeTemplateRepositoryImpl from "@/infrastructure/repositoriesImpl/challenge-template/ChallengeTemplateRepositoryImpl";
import ChallengeRepositoryImpl from "@/infrastructure/repositoriesImpl/challenge/ChallengeRepositoryImpl";
import StudentChallengesRepositoryImpl from "@/infrastructure/repositoriesImpl/academy/student-challenges/StudentChallengesRepositoryImpl";
import SubmittedProblemsRepositoryImpl from "@/infrastructure/repositoriesImpl/user/student/submitted-problems/SubmittedProblemsRepositoryImpl";
import StudentRepositoryImpl from "@/infrastructure/repositoriesImpl/user/student/StudentRepositoryImpl";
import ProblemRepositoryImpl from "@/infrastructure/repositoriesImpl/problem/ProblemRepositoryImpl";
import AssignedCurriculumProblemRepositoryImpl from "@/infrastructure/repositoriesImpl/assigned-curriculum-problem/AssignedCurriculumProblemRepositoryImpl";
import ConceptEnhancementRepositoryImpl from "@/infrastructure/repositoriesImpl/concept-enhancement/ConceptEnhancementRepositoryImpl";
import ClassRepositoryImpl from "@/infrastructure/repositoriesImpl/class/ClassRepositoryImpl";

// common
import { AccessStorageImpl } from "@/infrastructure/repositoriesImpl/common/storage/AccessStorageImpl";
import { AccessStorage } from "@/infrastructure/repositoriesImpl/common/storage/AccessStorageImpl";

const repositoriesRegisterInit = () => {
    // common
    container.register<AccessStorage>("AccessStorage", {
        useClass: AccessStorageImpl,
    });

    // domain
    container.register<UserRepository>("UserRepository", {
        useClass: UserRepositoryImpl,
    });
    container.register<ChallengeTemplateRepository>("ChallengeTemplateRepository", {
        useClass: ChallengeTemplateRepositoryImpl,
    });
    container.register<ChallengeRepository>("ChallengeRepository", {
        useClass: ChallengeRepositoryImpl,
    });
    container.register<StudentChallengeRepository>("StudentChallengeRepository", {
        useClass: StudentChallengesRepositoryImpl,
    });
    container.register<SubmittedProblemRepository>("SubmittedProblemRepository", {
        useClass: SubmittedProblemsRepositoryImpl,
    });
    container.register<StudentRepository>("StudentRepository", {
        useClass: StudentRepositoryImpl,
    });
    container.register<ProblemRepository>("ProblemRepository", {
        useClass: ProblemRepositoryImpl,
    });
    container.register<AssignedCurriculumProblemRepository>("AssignedCurriculumProblemRepository", {
        useClass: AssignedCurriculumProblemRepositoryImpl,
    });
    container.register<ConceptEnhancementRepository>("ConceptEnhancementRepository", {
        useClass: ConceptEnhancementRepositoryImpl,
    });
    container.register<ClassRepository>("ClassRepository", {
        useClass: ClassRepositoryImpl,
    });
};

export default repositoriesRegisterInit;
