import "reflect-metadata";
import "@/index.css";
import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import App from "./App.vue";
import router from "@/ui/routers";
import { VueQueryPlugin } from "@tanstack/vue-query";
import datePickerPlugin from "@/infrastructure/libs/plugins/date-picker/index";
import draggablePlugin from "@/infrastructure/libs/plugins/vue-draggable-plus/index";
import withUUID from "vue-uuid";

// stores
import stateRegisterInit from "@/infrastructure/stores/server/StateRegister";
import clientManagerRegisterInit from "@/infrastructure/stores/client/StateRegister";
import libsRegisterInit from "@/infrastructure/libs/LibsRegister";
import clientStateRegisterInit from "@/adapter/common/stores/client/ClientStateRegister";
import repositoriesRegisterInit from "@/infrastructure/repositoriesImpl/repositoriesRegister";
import { behaviorRegisterInit } from "@/domain/behaviorRegister";

// utils
import clickOutside from "@/ui/shared/utils/clickOutside";

const prepareMsw = async () => {
    if (import.meta.env.DEV && import.meta.env.VITE_MSW_TEST === "true") {
        const { worker } = await import("./infrastructure/libs/msw/browser");
        return worker.start({ onUnhandledRequest: "bypass" });
    }
};
// 먼저 repository 를 등록해야, 이후 pinia 내에서 container 내 접근이 가능합니다.
repositoriesRegisterInit();

const app = withUUID(createApp(App));
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.use(router);
app.use(VueQueryPlugin);
app.use(datePickerPlugin);
app.use(draggablePlugin);
app.directive("click-outside", clickOutside);

// 의존성을 주입합니다.
behaviorRegisterInit();
stateRegisterInit();
libsRegisterInit();
clientManagerRegisterInit();
clientStateRegisterInit();

prepareMsw().then(() => {
    console.log("MSW is ready");
    app.mount("#app");
});
